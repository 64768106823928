<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav"
  >
    <component
      :is="resolveNavComponent(item)"
      v-for="item in filteredItems"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue'
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue'

import {createNamespacedHelpers} from 'vuex'
import {MENU_SET_SITE_ADMIN_MENU_PERMISSION} from '@/store/settings/action'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isLoading: true,
    }
  },
  setup() {
    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }

    return {
      resolveNavComponent,
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      menuSetSiteAdminMenuPermission: 'menuSetSiteAdminMenuPermission',
    }),
    // Filter the items based on permissions
    filteredItems() {
      if (this.isLoading || this.menuSetSiteAdminMenuPermission.length === 0) return []

      return this.items.filter(item => {
        // '총판 관리'는 항상 표시
        if (item.title === '총판 관리') return true

        // '마스터 관리자'는 authMasterAdmin이 'Y'일 때만 표시
        if (item.title === '마스터 관리자') {
          return this.menuSetSiteAdminMenuPermission[0].authMasterAdmin === 'Y'
        }

        // '슈퍼 관리자'는 authSuperAdmin이 'Y'일 때만 표시
        if (item.title === '슈퍼 관리자') {
          return this.menuSetSiteAdminMenuPermission[0].authSuperAdmin === 'Y'
        }

        // 그 외 나머지 항목들은 authBasicAdmin이 'Y'일 때만 표시
        return this.menuSetSiteAdminMenuPermission[0].authBasicAdmin === 'Y'
      })
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...settingsStore.mapActions({
      $menuSetSiteAdminMenuPermission: MENU_SET_SITE_ADMIN_MENU_PERMISSION,
    }),
    async fetchData() {
      try {
        await this.$menuSetSiteAdminMenuPermission({
          userid: this.userData.userid,
        })
      } catch (error) {
        console.error('Failed to fetch admin settings:', error)
      } finally {
        // Set loading to false after fetching data
        this.isLoading = false
      }
    },
  },
}
</script>
